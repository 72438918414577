import React from "react";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import { handleClickModal } from "actions/User";
import "./styles.css";

const Banner = (props) => {
  const { handleClickModal } = props;
  
  return (
    <div className="app-wrapper p-0">
      <div className="w-100 text-center py-1"
        style={{
        color: "#404040",
        background: "#f9e4b1",
        borderRadius: "0px 0px 20px 20px",
        }}
      >
        <h3 className="mt-2" style={{opacity: "1", fontFamily: "Prompt-Regular"}}>
          <span style={{fontFamily: "Prompt-Medium"}}>¡Recuerda capturar tu código postal fiscal!</span> Tienes hasta el Martes 15/11/2022.
          <Link className="text-secadmon banner-link" to="/app/profile" onClick={() => handleClickModal(true)}> Registrar código</Link></h3>
      </div>
    </div>
  )
}

export default connect(null, {handleClickModal})(Banner);