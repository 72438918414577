import {
    USER_CP_BANNER,
    USER_CP_MODAL,
    USER_CLICK_MODAL
  } from "constants/ActionTypes";
  
  export function handleOpenModal(open) {
    return { type: USER_CP_MODAL, payload: { open } };
  }
  
  export function handleShowBanner(show) {
    return { type: USER_CP_BANNER, payload: { show } };
  }

  export function handleClickModal(click) {
    return { type:  USER_CLICK_MODAL, payload: { click }}
  }