import React from "react";
import { connect } from "react-redux";
import { handleOpenModal } from "actions/User";
import Banner from "./Banner";
import FiscalZipCodeModal from "./FiscalZipCodeModal";

const FiscalZipCode = (props) => {
  const { openModal, handleOpenModal } = props

  return (
    <>
      <Banner />
      <FiscalZipCodeModal isOpen={openModal} toggleOpen={() => {handleOpenModal(false)}} />
    </>
  )
}

const mapStateToProps = ({ user }) => {
  const { openModal } = user;
  return { openModal };
};

export default connect(mapStateToProps, {handleOpenModal})(FiscalZipCode);