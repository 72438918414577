import storage from "services/storage";
import { sendRequest } from "util/utils";

const saveTemplate = async data => {
  const token = `Bearer ${storage.getToken()}`,
    route = '/form_template',
    request = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data
    };
  return await sendRequest(route, request);
};

const getTemplates = async page => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/form_template?page=${page}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },

    };
  return await sendRequest(route, request);
}

const getTemplate = async id => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/form_template/${id}`,
    request = {
      method: "GET",
      headers: {
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },

    };
  return await sendRequest(route, request);
}

const updateTemplate = async (data, id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/form_template/${id}`,
    request = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data
    };
  return await sendRequest(route, request);
}
const deleteTemplate = async templateId => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/form_template/${templateId}`;
  const request = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    }
  };
  return await sendRequest(route, request);
}

const getFormAnswers = async (id) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/procedures/requests/answers/${id}`;
  const request = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    }
  };
  return await sendRequest(route, request);
}

const editFormAnswers = async (id, form) => {
  form.append("_method", "PUT")
  const token = `Bearer ${storage.getToken()}`;
  const route = `/procedures/requests/answers/${id}`;
  const request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
    body: form
  };
  return await sendRequest(route, request);
}

const requestAnswersEdit = async (id) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/procedures/requests/allow_edition/${id}`;
  const request = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    }
  };
  return await sendRequest(route, request);
}

const getGenericsQuestions = async (page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/form_template/questions?page=${page}`,
    request = {
      method: "GET",
      headers: {
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },

    };
  return await sendRequest(route, request);
}

const getGenericQuestion = async id => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/form_template/questions/${id}`,
    request = {
      method: "GET",
      headers: {
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },

    };
  return await sendRequest(route, request);
}

const deleteGenericQuestion = async id => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/form_template/questions/${id}`,
    request = {
      method: "DELETE",
      headers: {
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },

    };
  return await sendRequest(route, request);
}

const updateGenericQuestion = async (id, data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/form_template/questions/${id}/update`,
    request = {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data
    };
  return await sendRequest(route, request);
}

const getTemplatesByWorksAreas = async (page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/form_template/by/workarea?page=${page}`,
    request = {
      method: "GET",
      headers: {
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },

    };
  return await sendRequest(route, request);
}

const getQuestionLevel3CCT = async (cct) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/questions/lvl3/2/${cct}`,
    request = {
      method: "GET",
      headers: {
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },

    };
  return await sendRequest(route, request);
}

const getUserInfoFromEmail = async (email) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/users/get-by-email`,
    request = {
      method: "POST",
      headers: {
        Accept: 'application/json',
        "content-type": 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },
      body: JSON.stringify({email}),
    };
  return await sendRequest(route, request);
}

const getUserInfoFromCurp = async (curp) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/users/get-by-curp`,
    request = {
      method: "POST",
      headers: {
        Accept: 'application/json',
        "content-type": 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },
      body: JSON.stringify({curp}),
    };
  return await sendRequest(route, request);
}

const createProcedureRequestWithAppointment = async (procedureId, form) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/createFormularyAppointment/${procedureId}`;
  const request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
    body: form
  };
  return await sendRequest(route, request);
}

export default {
  saveTemplate,
  getTemplates,
  getTemplate,
  updateTemplate,
  deleteTemplate,
  getFormAnswers,
  getGenericsQuestions,
  editFormAnswers,
  requestAnswersEdit,
  getTemplatesByWorksAreas,
  getGenericQuestion,
  deleteGenericQuestion,
  updateGenericQuestion,
  getQuestionLevel3CCT,
  getUserInfoFromEmail,
  getUserInfoFromCurp,
  createProcedureRequestWithAppointment,
};
