import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { handleOpenModal, handleShowBanner, handleClickModal } from "actions/User";
import storage from "services/storage";

const HistoryListener = ({
    handleOpenModal,
    handleShowBanner,
    handleClickModal,
    clickModal
}) => {
    const location = useLocation();
    
    // locations listener
    useEffect(() => {
        const { user } = storage.getData() || {};
        const show = user && !user.cp;
        handleOpenModal(show && !clickModal);
        handleShowBanner(show);
        handleClickModal(false);
    }, [location]);
    
    return "";
};

const mapStateToProps = ({user}) => {
    return { clickModal: user.clickModal };
}

export default connect(mapStateToProps, {handleOpenModal, handleShowBanner, handleClickModal})(HistoryListener);