import storage from "services/storage";
import { sendRequest } from "util/utils";

const createRequest = async (data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = "/records/update_requests",
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data,
    };
  return await sendRequest(route, request);
};

const getRequests = async ({ page, status, term, startDate ,endDate, db }) => {

  const token = `Bearer ${storage.getToken()}`,
    route = `/records/update_requests?page=${page}${!!status ? `&status=${status}` : ''}`
    +`${!!term ? `&term=${term}` : ''}${!!startDate ? `&date_init=${startDate}` : ''}${!!endDate ? `&end_date=${endDate}` : ''}`
    +`${!!db ? `&database=${db}` : ''}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const getUpdateRequest = async ({ id }) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/update_requests/${id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const getAttachmentsFiles = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    //route = `/records/update_requests/files`,
    route = `/records/update_requests/files${id ? `?id=${id}` : ""}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const getHistoricFiles = async (type, page, id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/update_requests/files/${type}?page=${page}${
      id ? `&id=${id}` : ""
    }`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const approve = async ({ id, data }) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/update_requests/${id}/approve`,
    request = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: JSON.stringify(data),
    };
  return await sendRequest(route, request);
};

const updateNotApprovedRequest = async ({ id, data }) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/update_requests/${id}`,
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data,
    };
  return await sendRequest(route, request);
};

const updateRequestHistorical = async ({ page }) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/update_requests/historical?page=${page}`,
    request = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const addFilesToExpediente = async (data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/update_requests/files`,
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data,
    };
  return await sendRequest(route, request);
};

const searchExpediente = async (term) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/search?term=${term}`,
    request = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const getUserExpediente = async (curp, user_id) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/records/search/detail?curp=${curp}${
      !!user_id ? `&user_id=${user_id}` : ""
    }`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const downloadRecord = async (curp, user_id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/search/format?curp=${curp}${
      !!user_id ? "&user_id=" + user_id : ""
    }`,
    request = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request, true);
};

const downloadRecordRequestFormat = async (update_request_id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/update_requests/format/${update_request_id}`,
    request = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request, true);
};

const assignPermissions = async ({ data, user_id }) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/store/${user_id}`,
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data,
    };
  return await sendRequest(route, request);
};

const getPermissionsList = async ({ user_id }) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/permissions/${user_id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const getRequestsTracking = async (page, term) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/tracking?page=${page}${!!term ? `&term=${term}` : ""}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const getRequestTracking = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/tracking/${id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const activeMonitoring = async (request_id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/tracking/${request_id}`,
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const createActivityForTrackingRoom = async (tracking_id, data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/tracking/${tracking_id}/activity`,
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data,
    };
  return await sendRequest(route, request);
};

const getUsersTrackingRoom = async (tracking_id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/tracking/users/${tracking_id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const updateUsersTrackingRoom = async (tracking_id, data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/tracking/users/${tracking_id}`,
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data,
    };
  return await sendRequest(route, request);
};

const getRecordRequests = async (id, filter, page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/myrequests?page=${page}${
      !!id ? `&item=${id}` : ""
    }${!!filter ? `&status=${filter}` : ""}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const getMessages = async (data_update_request_id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/myrequests/${data_update_request_id}/messages`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const sendMessage = async (data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/myrequests/messages`,
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data,
    };
  return await sendRequest(route, request);
};

const markAsRead = async (data_update_request_messages_id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/myrequests/messages/${data_update_request_messages_id}`,
    request = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};





const getReport = async (status, db, startDate ,endDate ) => {
    const token = `Bearer ${storage.getToken()}`;
    const route = !!status
            ? `/records/report?status=${status}${!!startDate ? `&date_init=${startDate}` : ''}${!!endDate ? `&end_date=${endDate}` : ''}`
            : `/records/update_requests/report?database=${db}${!!startDate ? `&date_init=${startDate}` : ''}${!!endDate ? `&end_date=${endDate}` : ''}`

        const request = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: token,
                token: storage.getSSID(),
            }
        };
    return await sendRequest(route, request, true);
};

const getValidationDaysRequest = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/update_requests/dataOnRequest`,
    request = {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getSurveySocialMedia = async () => {
  const token = `Bearer ${storage.getToken()}`,
  //   route = `/survey/myrequests?page=${page}${
  //     !!id ? `&item=${id}` : ""
  //   }${!!filter ? `&status=${filter}` : ""}`,
  route = `/survey`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

export default {
  createRequest,
  getRequests,
  getUpdateRequest,
  getAttachmentsFiles,
  getHistoricFiles,
  approve,
  updateNotApprovedRequest,
  updateRequestHistorical,
  addFilesToExpediente,
  searchExpediente,
  getUserExpediente,
  downloadRecordRequestFormat,
  downloadRecord,
  assignPermissions,
  getPermissionsList,
  getRequestsTracking,
  getRequestTracking,
  activeMonitoring,
  createActivityForTrackingRoom,
  getUsersTrackingRoom,
  updateUsersTrackingRoom,
  getValidationDaysRequest,
  getRecordRequests,
  getMessages,
  sendMessage,
  markAsRead,
  getReport,
  getSurveySocialMedia,
};
