import storage from "services/storage";
import { sendRequest } from "util/utils";

const saveHelpContent = async (data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/tutorials`,
    request = {
      method: "POST",
      headers: {
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },
    body: data
    };
  return await sendRequest(route, request);
};

const getHelpContentList = async (page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/tutorials?page=${page}`,
    request = {
      method: "GET",
      headers: {
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const getHelpContentDetail = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/tutorials/${id}`,
    request = {
      method: "GET",
      headers: {
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const updateHelpContent = async (id, data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/tutorials/${id}`,
    request = {
      method: "POST",
      headers: {
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },
    body: data
    };
  return await sendRequest(route, request);
};

const deleteTutorial = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/tutorials/${id}`,
    request = {
      method: "DELETE",
      headers: {
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
}

const getHelpContentByRoute = async (routeName) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/tutorials-by-filters?pageName=${routeName}`,
    request = {
      method: "GET",
      headers: {
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },
      // body: JSON.stringify({pageNames: [routeName]}),
    };
  return await sendRequest(route, request);
}

export default {
  saveHelpContent,
  getHelpContentList,
  getHelpContentDetail,
  updateHelpContent,
  deleteTutorial,
  getHelpContentByRoute,
};