import {
    USER_CP_MODAL,
    USER_CP_BANNER,
    USER_CLICK_MODAL,
  } from "constants/ActionTypes";
  
  const initialState = {
    openModal: false,
    showBanner: false,
    clickModal: false,
  };
  
  const api = (state = initialState, action) => {
    switch (action.type) {
      case USER_CP_MODAL:
        return {
          ...state,
          openModal: action.payload.open,
        };
      case USER_CP_BANNER:
        return {
          ...state,
          showBanner: action.payload.show,
        };
      case USER_CLICK_MODAL:
        return {
          ...state,
          clickModal: action.payload.click
        }
      default:
        return state;
    }
  };
  
  export default api;
  