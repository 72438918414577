import storage from "services/storage";
import { sendRequest } from "util/utils";

const getCategories = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = "/procedures/categories-areas",
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getProceduresByCategory = async (category, page, maturityLevel) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/byCategory/${category}?page=${page}&level=${maturityLevel}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getMostSearchedProcedures = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = "/procedures/most_searched",
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getMostFrequentProcedures = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = "/procedures/most_frequent",
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getLevel3Procedures = async (page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/N3?page=${page}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getlevel4Procedures = async (page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/level_four?page=${page}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getValidationProcedures = async (page, search, status, work_area, procedure_validation) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/index_validator?page=${page}${!!search ? `&term=${search}` : ""}${!!status ? `&status=${status}` : ""}${!!work_area ? `&work_area=${work_area}` : ""}${!!procedure_validation ? `&procedure_validation=${procedure_validation}` : ""}`,
    request = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getProceduresByWorkArea = async (workAreaId, page, selector = false, maturityLevel, user_id) => {
  const route = selector
    ? `/procedures/by_administrative_area/${workAreaId}?selector=true&user_id=${user_id}`
    : `/procedures/by_administrative_area/${workAreaId}?page=${page}&level=${maturityLevel}`;
  const token = `Bearer ${storage.getToken()}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getWorkAreasByUser = async (workAreaId, type, userId, level) => {
  // type = 'responsible' || 'manager'
  const route = `/procedures/administrative_area/${workAreaId}/selector?type=${type}&user_id=${userId}&level=${level}`;
  const token = `Bearer ${storage.getToken()}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getProceduresBySearch = async (search, page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/by_word/${search}?page=${page}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getProcedure = async (id, type) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/${id}?type=${type}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getLatestApprovedProcedure = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/show2/${id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const createProcedure = async data => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures`,
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data
    };
  return await sendRequest(route, request);
};

const createProcedureRequest = async (procedureId, body) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/${procedureId}/requests`,
    request = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body
    };
  return await sendRequest(route, request);
};

const cancelProcedureRequest = async (procedureRequestId) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/requests/${procedureRequestId}/cancel`,
    request = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
}

const editProcedure = async ({ id, data }) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/${id}`,
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data
    };
  return await sendRequest(route, request);
};

const categoriesAreas = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/categories-areas`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const rolesList = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/roles-list`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const deleteProcedure = async (id, data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/${id}`,
    request = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data
    };
  return await sendRequest(route, request);
};

const getMyProceduresList = async (page = 1, item, status = '') => {
  const route = (status !== '')
    ? `/procedures/requests?page=${page}&item=${item}&status=${status}`
    : `/procedures/requests?page=${page}&item=${item}`;
  const token = `Bearer ${storage.getToken()}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getProcedureRequest = async (id) => {
  const route = `/procedures/requests/${id}`
  const token = `Bearer ${storage.getToken()}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getResponsibleProceduresList = async (page = 1, item, status = '', undersecretary, workArea,areaManagement, trafficLight = "") => {
  const route = `/procedures/requests/responsible?page=${page}&item=${item}${status ? `&status=${status}` : ""}${workArea ? `&work_area=${workArea}` : ""}${undersecretary ? `&undersecretary=${undersecretary}` : ""}${areaManagement ? `&area_management=${areaManagement}` : ""}&traffic_light=${trafficLight}`
  const token = `Bearer ${storage.getToken()}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const updateProcedureRequest = async (id, procedure) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/requests/${id}`,
    request = {
      method: "POST",
      headers: {
        Authorization: token,
        token: storage.getSSID(),
      },
      body: procedure
    };
  return await sendRequest(route, request);
};

const updateProcedureAppointmentRequest = async (id, form) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/requests/${id}/status-appointment`,
    request = {
      method: "POST",
      headers: {
        Authorization: token,
        token: storage.getSSID(),
      },
      body: form
    };
  return await sendRequest(route, request);
};

const downloadProcedureResponse = async id => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/my-procedures/response/${id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request, true);
};

const satisfactionSurvey = async value => {
  const data = new FormData();
  data.append("good", false);
  data.append("regular", false);
  data.append("bad", false);
  data.set(value, true);

  const token = `Bearer ${storage.getToken()}`,
    route = `/satisfaction_survey`,
    request = {
      method: "POST",
      headers: {
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data
    };
  return await sendRequest(route, request);
};

const satisfactionSurveyStats = async value => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/satisfaction_survey`,
    request = {
      method: "GET",
      headers: {
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getSurveyStatus = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/satisfaction_survey/status`,
    request = {
      method: "GET",
      headers: {
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const updateProceduresStatus = async (page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/updateProceduresFromExcel?page=${page}`,
    request = {
      method: "GET",
      headers: {
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
}

const getProcedureComments = async (id, page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/questions?procedure_id=${id}&page=${page}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
}

const addComment = async (data, id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/question?procedure_id=${id}`,
    request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: JSON.stringify(data)
    };
  return await sendRequest(route, request);
}

const replyComment = async (data, procedureId, commentId) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/answer?procedure_id=${procedureId}&question_id=${commentId}`,
    request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: JSON.stringify(data)
    };
  return await sendRequest(route, request);
}

const cancelDate = async data => { // Por Editar
  const token = `Bearer ${storage.getToken()}`,
    route = `/`,
    request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: JSON.stringify(data)
    };
  return await sendRequest(route, request);
}

const loadMessages = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/requests/${id}/messages`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
}

const sendMessage = async data => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/requests/messages`,
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data
    };
  return await sendRequest(route, request);
}

const markAsRead = async id => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/requests/messages/${id}`,
    request = {
      method: "PUT",
      headers: {

        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
}

const loadPrioritizedMessages = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/requests/prioritized/${id}/messages`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
}

const sendPrioritizedMessage = async data => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/requests/prioritized/messages`,
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data
    };
  return await sendRequest(route, request);
}

const markPrioritizedAsRead = async id => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/requests/prioritized/messages/${id}`,
    request = {
      method: "PUT",
      headers: {

        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
}

const newPrioritizedMessage = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/requests/prioritized/${id}/isThereAnUnseenMessage`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
}

const updateProcedureStatus = async (id, data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/${id}/approve`,
    request = {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data
    };
  return await sendRequest(route, request);
}

const disableProcedure = async (id, data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/${id}/disable`,
    request = {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data
    };
  return await sendRequest(route, request);
}
const enableProcedure = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/${id}/enable`,
    request = {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
}

const saveAlertTime = async (data, procedureId) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/${procedureId}/alert_time`,
    request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data
    };
  return await sendRequest(route, request);
}

const getSubsecretaries = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/undersecretary`,
    request = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
}

const downloadReport = async (procedure_id, start_date, end_date) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/${procedure_id}/report?start_date=${start_date}&end_date=${end_date}`,
    request = {
      method: "GET",
      headers: {
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request, true);
}

const getUndersecretaries = async () => {
    const token = `Bearer ${storage.getToken()}`,
        route = `/undersecretaries`,
        request = {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: token,
                token: storage.getSSID(),
            }
        };
    return await sendRequest(route, request);
}

const prioritize = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/requests/${id}/priority`,
    request = {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
}
const getAdministrativesAreas = async () => {
    const token = `Bearer ${storage.getToken()}`,
        route = `/administrative-areas`,
        request = {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: token,
                token: storage.getSSID(),
            }
        };
    return await sendRequest(route, request);
}

const updateMultipleProcedureRequest = async (procedures) => {
    const token = `Bearer ${storage.getToken()}`,
        route = `/procedures/requests/update/multiple`,
        request = {
            method: "POST",
            headers: {
                Authorization: token,
                token: storage.getSSID(),
            },
            body: procedures
        };
    return await sendRequest(route, request);
};

const getValidationRequestsList = async (page = 1, status, term ) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/requests/sicsse?page=${page}&status=${status}&term=${term}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const validateProcedureRequest = async ( id, data ) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/requests/sicsse/approve/${id}`,
    request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data
    };
  return await sendRequest(route, request);
};

const getSafeguardsFromProcedureRequest = async (procedureRequestId, page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/sicsse-requests/${procedureRequestId}?page=${page}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
}

const getAllSafeguardsFromProcedureRequest = async (procedureRequestId) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/sicsse-requests-unpagged/${procedureRequestId}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
}

const getProcedureDraft = async (procedure_id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/draft/show${procedure_id ? `/${procedure_id}` : ""}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
}

const saveProcedureDraft = async (data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/draft/create`,
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data
    };
  return await sendRequest(route, request);
}

export default {
  getCategories,
  getSubsecretaries,
  getProceduresByCategory,
  getMostSearchedProcedures,
  getMostFrequentProcedures,
  getProceduresByWorkArea,
  getWorkAreasByUser,
  getProceduresBySearch,
  getLevel3Procedures,
  getlevel4Procedures,
  getProcedure,
  getLatestApprovedProcedure,
  createProcedure,
  createProcedureRequest,
  cancelProcedureRequest,
  deleteProcedure,
  editProcedure,
  categoriesAreas,
  rolesList,
  getMyProceduresList,
  getResponsibleProceduresList,
  updateProcedureRequest,
  downloadProcedureResponse,
  satisfactionSurvey,
  satisfactionSurveyStats,
  getSurveyStatus,
  updateProceduresStatus,
  getProcedureComments,
  addComment,
  replyComment,
  cancelDate,
  loadMessages,
  sendMessage,
  markAsRead,
  loadPrioritizedMessages,
  sendPrioritizedMessage,
  markPrioritizedAsRead,
  newPrioritizedMessage,
  updateProcedureAppointmentRequest,
  getProcedureRequest,
  updateProcedureStatus,
  disableProcedure,
  getValidationProcedures,
  enableProcedure,
  saveAlertTime,
  downloadReport,
  getUndersecretaries,
  prioritize,
  getAdministrativesAreas,
  updateMultipleProcedureRequest,
  getValidationRequestsList,
  validateProcedureRequest,
  getProcedureDraft,
  saveProcedureDraft,
  getSafeguardsFromProcedureRequest,
  getAllSafeguardsFromProcedureRequest,
};
