import React from "react";
import {connect} from "react-redux";
import { Button, IconButton } from "@material-ui/core";
import { Modal } from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import { handleClickModal } from "actions/User";

const FiscalZipCodeModal = (props) => {
  const {isOpen, toggleOpen, handleClickModal} = props;

  return (
    <Modal
      className="modal-box modal-box-mail"
      toggle={toggleOpen}
      isOpen={isOpen}
      style={{ zIndex: 2600 }}
      backdrop={"static"}
      centered
    >
      <div className="w-100 text-right">
        <IconButton className="" onClick={toggleOpen}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="w-100 p-4">
        <p className="mt-2" style={{opacity: "1", fontFamily: "Prompt-Regular"}}>
          <span style={{fontFamily: "Prompt-Medium"}}>¡Recuerda capturar tu código postal fiscal!</span> Tienes hasta el Martes 15/11/2022,
          si tienes dudas de dónde obtenerlo da clic <a href="" target="_blank" className="text-secadmon">aquí</a>.
        </p>
        <p>Para cargar tu documento ve a mi expediente o da clic en el botón “Registro”.</p>
      </div>
      <div className="w-100 text-center pb-3">
        <Link to="/app/profile">
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className="mb-0 mx-1 mi_muro-btn_magenta"
            onClick={() => handleClickModal(true)}
          >
            <p className="mb-0 mr-1">Registro</p>
          </Button>
        </Link>
      </div>
    </Modal>
  );
}

export default connect(null, { handleClickModal })(FiscalZipCodeModal);